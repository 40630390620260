import { ContentSkeleton } from '../content-skeleton';
import { Box } from '@chakra-ui/react';
import { useContext, useEffect, useState } from 'react';
import { StudentGradeContext, useFullScreen } from '@lon/shared/contexts';
import { StudentAssignmentStatusTransformed } from '@lon/shared/enums';
import { useAuth } from '@lon/shared/hooks';
import { ShareResults } from '@lon/shared/requests';
import { getStudentAssignmentStatus } from '@lon/shared/utils';
import { AssessmentReport } from '@lon/suit/components';
import { resizeMejsVideos } from '@lon/suit/utils';
import { AssessmentContentProps } from './duck';
import { AssessmentQuestions } from './components';

const AssessmentContent: React.FC<AssessmentContentProps> = ({
  redirectPath,
  learnosityReferenceId,
  shareResults,
  status,
  randomizeQuestionsOrder,
  id,
  isDistrictAssignment,
  isParent,
  parentStudentId,
}) => {
  const { isStudentBelowK3 } = useContext(StudentGradeContext);
  const [learnosityLoading, setLearnosityLoading] = useState(true);
  const transformedStatus = getStudentAssignmentStatus(status);
  const { isFullScreen } = useFullScreen();
  const [{ user }] = useAuth();

  const isReport =
    (shareResults === ShareResults.WithCorrectAnswers ||
      shareResults === ShareResults.WithoutCorrectAnswers) &&
    transformedStatus === StudentAssignmentStatusTransformed.Graded;

  useEffect(() => {
    const pageContent = document.querySelector('.page-content');
    const resizeObserver = new ResizeObserver(() => resizeMejsVideos());

    if (!learnosityLoading && pageContent) {
      resizeObserver.observe(pageContent);

      return () => {
        resizeObserver.unobserve(pageContent);
      };
    }

    return undefined;
  }, [learnosityLoading]);

  return (
    <Box pos="relative" w="full" h="full">
      {learnosityLoading && (
        <ContentSkeleton
          position="absolute"
          top="0"
          right="0"
          bottom="0"
          left="0"
          zIndex="1"
        />
      )}
      <Box
        opacity={learnosityLoading ? 0 : 1}
        backgroundColor="white"
        height="full"
        overflow="hidden"
        borderRadius={{
          lg: isStudentBelowK3 ? (isFullScreen ? '0px' : '20px') : 'unset',
        }}
      >
        {isParent && !parentStudentId ? (
          <></>
        ) : isReport ? (
          <AssessmentReport
            shareResults={shareResults}
            id={id}
            setLearnosityLoading={setLearnosityLoading}
            isDistrictAssignment={isDistrictAssignment}
            userId={user.userId}
            learnosityLoading={learnosityLoading}
            isParent={isParent}
            parentStudentId={parentStudentId}
          />
        ) : (
          <AssessmentQuestions
            redirectPath={redirectPath}
            learnosityReferenceId={learnosityReferenceId}
            setLearnosityLoading={setLearnosityLoading}
            randomizeQuestionsOrder={randomizeQuestionsOrder}
            isDistrictAssignment={isDistrictAssignment}
          />
        )}
      </Box>
    </Box>
  );
};

export default AssessmentContent;
