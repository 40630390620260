import React, {
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
} from 'react';
import { TeacherResource } from '@lon/shared/requests';
import {
  AclPermission,
  TransformedElements,
  TransformedResourceElements,
  TransformedSection,
  WorkingLocationContext,
} from '@lon/shared/types';

export const AclContext = React.createContext<{
  value: AclPermission[];
  loading: boolean;
}>({
  value: [],
  loading: true,
});

export const CurrentSchoolContext = createContext<{
  currentSchoolId?: string;
  setCurrentSchoolId: (schoolId: string) => void;
}>({
  currentSchoolId: undefined,
  setCurrentSchoolId: (schoolId) => {},
});

export const WorkingLocation = createContext<WorkingLocationContext>({
  application: undefined,
  currentSchoolId: undefined,
  setCurrentSchoolId: (schoolId) => {},
  permissions: [],
  schoolIds: [],
  userType: undefined,
});

export const FormMeta = createContext<{
  needFormPrompt: React.MutableRefObject<boolean>;
}>({
  needFormPrompt: { current: false },
});

export const StudentGradeContext = createContext(
  {} as unknown as {
    isStudentBelowK3: boolean | null;
  }
);

export const PageContentContext = createContext<{
  pageContentRef: React.MutableRefObject<HTMLDivElement | null>;
}>({
  pageContentRef: { current: null },
});

export interface FullScreenContextType {
  isFullScreen: boolean;
  setIsFullScreen: Dispatch<SetStateAction<boolean>>;
}

export const FullScreenContext = createContext<FullScreenContextType>({
  isFullScreen: false,
  setIsFullScreen: () => {},
});
export interface StudentFullScreenContextType {
  isFullScreen: boolean;
  setIsFullScreen: Dispatch<SetStateAction<boolean>>;
}

export const StudentFullScreenContext = createContext<
  StudentFullScreenContextType | undefined
>(undefined);

export const useFullScreen = () => {
  const context = useContext(StudentFullScreenContext);
  if (!context) {
    throw new Error(
      'useFullScreen must be used within a StudentFullScreenProvider'
    );
  }
  return context;
};

export type TabArgs = {
  editingRows: string[];
  setEditingRows: Dispatch<SetStateAction<string[]>>;
  error?: boolean;
  setError?: Dispatch<SetStateAction<boolean>>;
};
export const TabContext = React.createContext<TabArgs>({
  editingRows: [],
  setEditingRows: () => {},
  error: false,
  setError: () => {},
});

interface FrostContextType {
  frostData?: any | null;
  setFrostData: React.Dispatch<React.SetStateAction<any | null>>;
  setLoading: React.Dispatch<React.SetStateAction<any | null>>; // TODO
  loading: boolean;
  refetch: () => void;
}

export const FrostContentPlayerContext = React.createContext<FrostContextType>({
  frostData: null,
  setFrostData: (value) => {
    return value;
  },
  loading: false,
  setLoading: (value) => {
    return value;
  },
  refetch: () => {},
});

interface ClassesContext {
  loading: boolean;
  data: { id: string; name: string }[];
}

export const ClassesContext = createContext<ClassesContext>({
  data: [],
  loading: false,
});

export interface JsonMetadata {
  key_name: string;
  value: string;
  contentGroup: string[];
  curriculumArea: string;
  displayName: string;
  editionName: string;
  gradeLevel: string;
  instructionalDays: string;
  internalName: string;
  positionNumber: string;
  projectType: string;
  targetEnv: string;
}

export interface JsonTaxonomy {
  HumanCodingScheme: string;
  breadcrumb: string;
  title: string;
  uuid: string;
}

export interface JsonContentStructure {
  identifier?: string;
  items?: {
    identifier?: string;
    items?: any;
    metadata?: any;
    tags?: string[];
    title?: string;
  }[];
  metadata?: JsonMetadata[];
  title?: string;
}

export interface JsonViewStructure {
  href: string;
  identifier: string;
  lorResources: {
    type: string;
    referenceId: string;
  }[];
  metadata: {
    hide: 'Yes' | 'No';
  };
  taxonomy: JsonTaxonomy[];
  title: string;
  isStudentView?: boolean;
  elementId?: string;
}

export interface JsonElementStructure {
  elementFiles: {
    href: string;
    identifier: string;
    metadata: { fileTitle: string; fileType: string[] };
    taxonomy: string[];
    title: string;
  }[];
  identifier: string;
  metadata: {
    elementName: string;
    elementIconType: string;
    scopeDisplayName: string;
    sectionTitle: string;
  };
  studentView: JsonViewStructure[];
  tags: string[];
  teacherView: JsonViewStructure;
  title: string;
}

export interface JsonSectionStructure {
  elements: JsonElementStructure[];
  identifier: string;
  metadata: { sectionType: string; sectionTitle: string };
  title: string;
}

export interface JsonScopeStructure {
  active?: boolean;
  banner?: string;
  contentGroups?: string[];
  contentLocation?: string;
  contentPaths?: string[];
  contentStructure?: JsonContentStructure[];
  created?: string;
  displayName?: string;
  editionName?: string;
  elementTaxonomy?: JsonTaxonomy;
  elementTitles?: string[];
  home?: {
    identifier: string;
    landingPage: JsonElementStructure;
    sideBar: JsonElementStructure[];
  };
  image?: string;
  internalName?: string;
  lastUpdated?: string;
  launchUrl?: string;
  metadata?: JsonMetadata;
  objectID?: string;
  platform?: string;
  positionNumber?: number;
  projectID?: string;
  projectType?: string;
  sections?: JsonSectionStructure[];
  state?: string;
  structureUrl?: string;
  tags?: string[];
  taxonomy?: JsonTaxonomy[];
  version?: number;
}

export interface IScopeContext {
  original?: JsonScopeStructure;
  elementsBySection: TransformedElements;
  loading: boolean;
  refetch: () => void;
  attempts: number;
  setAttempts: React.Dispatch<React.SetStateAction<number>>;
  sections?: TransformedSection[];
  projectType?: string;
  scopeName?: string;
  called?: boolean;
  metadata?: {
    curriculumArea: string;
    gradeLevel: string;
    displayName?: string;
  };
}

export const ScopeContext = createContext<IScopeContext>({
  elementsBySection: {},
  loading: true,
  refetch: () => {},
  attempts: 0,
  setAttempts: () => {},
  scopeName: '',
  called: false,
});

export const ResourceSectionsListContext = createContext<{
  isResourceLoading: boolean;
  elementsBySection: TransformedResourceElements;
  resource?: TeacherResource;
}>({ isResourceLoading: true, elementsBySection: {} });

export * from './online-mode';
