import { Text } from '@chakra-ui/react';
import { useContext } from 'react';
import { generatePath, useParams } from 'react-router-dom';
import { StudentGradeContext } from '@lon/shared/contexts';
import { useMediaQuery } from '@lon/shared/hooks';
import { StudentNotebookCard } from '@lon/suit/components';
import { routes } from '@lon/suit/configs';
import { notebookCoverImageSrc } from '@lon/suit/constants';
import { types } from './duck';
const Card: React.FC<types.CardProps> = ({
  title,
  id,
  curriculumArea,
  gradeOrCourse,
  isScopeCard,
  showGradeOrCourse = false,
  ...rest
}) => {
  const [isDesktop] = useMediaQuery('(min-width: 1024px)');
  const { notebookId } = useParams();
  const { isStudentBelowK3 } = useContext(StudentGradeContext);
  const isActiveCard = notebookId === id;

  return (
    <StudentNotebookCard
      isActiveCard={isActiveCard}
      title={
        <>
          <Text
            variant="n1"
            {...(isDesktop &&
              isScopeCard && {
                wordBreak: 'break-word',
                maxWidth: isStudentBelowK3 ? '188px' : '144px',
              })}
          >
            {title}
          </Text>
          {showGradeOrCourse && isStudentBelowK3 && (
            <Text
              variant="s2"
              color="primary.600"
              {...(isDesktop &&
                isScopeCard && {
                  wordBreak: 'break-word',
                  maxWidth: isStudentBelowK3 ? '188px' : '144px',
                })}
            >
              {Number(gradeOrCourse) > 0
                ? `Grade ${gradeOrCourse}`
                : gradeOrCourse}
            </Text>
          )}
        </>
      }
      imageSrc={(notebookCoverImageSrc as any)[curriculumArea]?.[gradeOrCourse]}
      link={generatePath(routes.notebook.scopes, {
        notebookId: id,
      })}
      {...rest}
    />
  );
};

export default Card;
