import { Flex, Icon, Text } from '@chakra-ui/react';
import { rocketNoData } from '@lon/shared/assets';
import { useTranslation } from 'react-i18next';
import { ErrorMessage } from '@lon/shared/components';

const AssignmentNoContent: React.FC = () => {
  const { t } = useTranslation();

  return (
    <ErrorMessage
      fullWidth
      title={<></>}
      body={
        <Flex direction="column" justify="center" maxW="500px" w="100%" gap="6">
          <Text variant="h5">{t('notebook.assignment.emptyMessage')}</Text>
          <Icon as={rocketNoData} h="105px" w="150px" m="0 auto" />
        </Flex>
      }
      actions={<></>}
    />
  );
};

export default AssignmentNoContent;
