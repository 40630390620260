import { StudentFullScreenProvider } from '../pages/student/notebook/components';
import '../styles.css';
import { Flex } from '@chakra-ui/react';
import React, { useContext, useEffect, useMemo, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { useInterceptor } from '@lon/api';
import {
  AsyncOutlet,
  ConditionalWrapper,
  Footer,
  ImpersonateWrapper,
  ModalContainer,
} from '@lon/shared/components';
import {
  IMPERSONATE_WRAPPER_HEIGHT_REM,
  IMPERSONATE_WRAPPER_WIDTH_REM,
  MODALS,
  isMobileDevice,
  showOutletOnly,
} from '@lon/shared/constants';
import {
  CurrentSchoolContext,
  FormMeta,
  StudentGradeContext,
  WorkingLocation,
} from '@lon/shared/contexts';
import { AclApplication } from '@lon/shared/gql/types';
import {
  useAcl,
  useGoogleAnalytics,
  useGradeLevel,
  useMediaQuery,
  useThemeStyleValue,
} from '@lon/shared/hooks';
import { useAuth } from '@lon/shared/hooks';
import { UserTypeEnum } from '@lon/shared/requests';
import {
  OPENED_MODAL,
  effectOnChangeLang,
  replaceImagesBasedOnLanguage,
  replaceMediaLanguageAfterLoad,
} from '@lon/shared/utils';
import { Header } from '@lon/suit/components';
import {
  GoogleTranslate,
  getCurrentSuite,
  injectLanguageSwitcher,
} from '@lon/suit/utils';
import { appOperations, useChameleon } from './duck';

injectLanguageSwitcher();
effectOnChangeLang();
replaceMediaLanguageAfterLoad();

const App = () => {
  const [auth] = useAuth();
  const location = useLocation();
  const acl = useAcl<AclApplication[]>();
  const [isTablet] = useMediaQuery('(min-width: 768px)');
  const needFormPrompt = useRef(false);
  const { isStudentBelowK3 } = useGradeLevel();
  const { currentSchoolId, setCurrentSchoolId } =
    useContext(CurrentSchoolContext);

  useGoogleAnalytics('suit');

  useInterceptor('request', appOperations.prepareRequest);

  useInterceptor('response', appOperations.prepareSuccess, (error) =>
    appOperations.prepareFailure({ error })
  );

  React.useEffect(() => {
    const savedNewRole = JSON.parse(localStorage.getItem('newRole') as string);
    // TODO Remove when user role update gets done
    savedNewRole && OPENED_MODAL(MODALS.PasswordUpdate);
  }, []);

  useEffect(() => {
    replaceImagesBasedOnLanguage();
    replaceImagesBasedOnLanguage();
  }, [location.pathname]);

  useChameleon();

  const workingLocation = useMemo(() => {
    const currentApplication = getCurrentSuite(auth?.profileType);

    const currentAclApp = acl?.find((app) => {
      return app.name === currentApplication;
    });

    const currentAclSchoolIds = currentAclApp?.schools?.reduce<string[]>(
      (acc, sc) => {
        return [...acc, ...sc.schoolIds];
      },
      []
    );

    return {
      application: currentApplication,
      currentSchoolId,
      setCurrentSchoolId,
      currentAppSchoolIds: currentAclSchoolIds || [],
      permissions: (auth?.user.permissions || []) as any,
      schoolIds: auth?.user?.schoolIds || [],
      userType: auth?.user?.type,
    };
  }, [currentSchoolId, auth?.user, acl]);

  const fullHeight = isMobileDevice ? '100dvh' : '100vh';

  return (
    <WorkingLocation.Provider value={workingLocation}>
      <FormMeta.Provider value={{ needFormPrompt }}>
        <ConditionalWrapper
          condition={auth.user.impersonated}
          wrapper={(children) => (
            <ImpersonateWrapper>{children}</ImpersonateWrapper>
          )}
        >
          <StudentFullScreenProvider>
            <ConditionalWrapper
              condition={auth?.user?.type === UserTypeEnum.Student}
              wrapper={(children) => (
                <StudentGradeContext.Provider value={{ isStudentBelowK3 }}>
                  {children}
                </StudentGradeContext.Provider>
              )}
            >
              <Flex
                direction="column"
                h={
                  auth.user.impersonated
                    ? `calc(100vh - ${IMPERSONATE_WRAPPER_HEIGHT_REM}rem)`
                    : fullHeight
                }
                w={
                  auth.user.impersonated
                    ? `calc(100vw - ${IMPERSONATE_WRAPPER_WIDTH_REM}rem)`
                    : '100vw'
                }
                maxH={fullHeight}
                overflow="hidden"
                backgroundColor="white"
                position="relative"
              >
                {!showOutletOnly && <Header />}
                <Flex
                  w="full"
                  flex="1 0 auto"
                  maxW="var(--page-width-max)"
                  alignSelf="center"
                  px={{ base: 0, b1281: 8 }}
                  pt={{ base: 0, b1281: 4 }}
                  backgroundColor={useThemeStyleValue('none', 'secondary.1000')}
                >
                  <AsyncOutlet />
                </Flex>
                {!auth.user.impersonated && isTablet && !showOutletOnly && (
                  <Footer />
                )}
                <Flex display="none">
                  <GoogleTranslate />
                </Flex>
                <ModalContainer />
              </Flex>
            </ConditionalWrapper>
          </StudentFullScreenProvider>
        </ConditionalWrapper>
      </FormMeta.Provider>
    </WorkingLocation.Provider>
  );
};

export default App;
