import { Box, IconButton } from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Icon } from '@lon/shared/components';
import { isDevMode } from '@lon/shared/constants';
import { useFullScreen } from '@lon/shared/contexts';
import { useAuth } from '@lon/shared/hooks';
import {
  AssignmentLearnosityRenderingType,
  AssignmentLearnosityState,
  AssignmentLearnosityType,
  useResetAssignmentStatusMutation,
  useSignRequestForDistrictAssignmentMutation,
  useSignRequestForStudentAssignmentMutation,
} from '@lon/shared/requests';
import {
  clearQueryCache,
  replaceImagesBasedOnLanguage,
  replaceVideosBasedOnLanguage,
} from '@lon/shared/utils';
import { AssignmentPrompt } from '@lon/suit/components';
import {
  AssessmentQuestionsProps,
  createReadspeakerButton,
  createReadspeakerScript,
  getUnattemptedQuestions,
} from './duck';

const AssessmentQuestions: React.FC<AssessmentQuestionsProps> = ({
  setLearnosityLoading,
  redirectPath,
  learnosityReferenceId,
  randomizeQuestionsOrder,
  isDistrictAssignment,
}) => {
  const itemsApp = useRef<ReturnType<typeof window.LearnosityItems.init>>();
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [auth] = useAuth();
  const navigate = useNavigate();
  const { isFullScreen, setIsFullScreen } = useFullScreen();
  const [signAssignment] = useSignRequestForStudentAssignmentMutation();
  const [signDistrictAssignment] =
    useSignRequestForDistrictAssignmentMutation();
  const [resetAssignmentStatus] = useResetAssignmentStatusMutation();
  const assessRef = useRef<HTMLDivElement>(null);

  const [showReadspeaker, setShowReadspeaker] = React.useState(false);
  const isReadspeakerEnabledRef = React.useRef(false);
  const readerScriptRef = React.useRef<HTMLScriptElement | null>(null);
  // handling for on full screen here...
  const handleOpenFullScreen = () => {
    setIsFullScreen(true);

    const elem = document.documentElement as any;

    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.mozRequestFullScreen) {
      // Firefox
      elem.mozRequestFullScreen();
    } else if (elem.webkitRequestFullscreen) {
      // Chrome, Safari and Opera
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) {
      // IE/Edge
      elem.msRequestFullscreen();
    }
  };

  const handleExitFullScreen = () => {
    setIsFullScreen(false);

    const documentVar = document as any;

    if (!documentVar?.fullscreenElement) {
      return;
    }
    if (documentVar.exitFullscreen) {
      documentVar.exitFullscreen();
    } else if (documentVar.mozCancelFullScreen) {
      // Firefox
      documentVar.mozCancelFullScreen();
    } else if (documentVar.webkitExitFullscreen) {
      // Chrome, Safari, and Opera
      documentVar.webkitExitFullscreen();
    } else if (documentVar.msExitFullscreen) {
      // IE/Edge
      documentVar.msExitFullscreen();
    }
  };
  const handleFullScreenChange = () => {
    const isCurrentlyFullScreen = !!document.fullscreenElement;
    setIsFullScreen(isCurrentlyFullScreen);
  };

  useEffect(() => {
    document.addEventListener('fullscreenchange', handleFullScreenChange);
    const handleKeydown = (event: any) => {
      if (event.key === 'Escape' && isFullScreen) {
        handleExitFullScreen();
      }
    };

    window.addEventListener('keydown', handleKeydown);
    return () => {
      document.removeEventListener('fullscreenchange', handleFullScreenChange);
      window.removeEventListener('keydown', handleKeydown);
    };
  }, [isFullScreen]);

  const initItemsApp = (signedRequest: any) => {
    itemsApp.current = window.LearnosityItems.init(JSON.parse(signedRequest), {
      readyListener() {
        if (itemsApp.current) {
          const incompleted = getUnattemptedQuestions(itemsApp.current);
          if (incompleted.length === 0) {
            setHasUnsavedChanges(true);
          }

          Object.keys(itemsApp.current.questions() || {}).forEach(
            (questionId) => {
              if (itemsApp.current) {
                itemsApp.current.question(questionId).on('changed', () => {
                  setHasUnsavedChanges(true);
                });
              }
            }
          );

          itemsApp.current.on('test:save:success', function () {
            setHasUnsavedChanges(false);
          });

          itemsApp.current.on('test:submit', function () {
            setHasUnsavedChanges(false);
          });
        }

        assessRef.current
          ?.querySelectorAll<SVGUseElement>('use[*|href]')
          .forEach((el) => {
            const baseHref = isDevMode() ? '/' : '/suite';

            el.setAttributeNS(
              'http://www.w3.org/1999/xlink',
              'xlink:href',
              el.href.baseVal.replace(baseHref, '')
            );
          });

        setLearnosityLoading(false);

        itemsApp.current?.on('test:submit:success', async () => {
          if (!isDistrictAssignment) {
            await resetAssignmentStatus({
              variables: {
                activityParams: {
                  activityId: learnosityReferenceId || '',
                  studentId: auth.user.userId,
                },
              },
            });
          }

          clearQueryCache([
            'studentStudentAssignments',
            'districtStudentAssignments',
          ]);
        });
        itemsApp.current?.on('test:finished:save', () => {
          navigate(redirectPath);
        });
        itemsApp.current?.on('test:finished:submit', () => {
          navigate(redirectPath);
        });

        // click and listen mode in readspeaked prevents the user from typing in the input fields several chars
        document.querySelectorAll('input, textarea').forEach((element) => {
          element.addEventListener('keydown', (e) => {
            e.stopPropagation();
          });
        });

        const skipFromReadingItems = document.querySelectorAll(
          '.lrn_response_wrapper .lrn-possible-answer .lrn_contentWrapper, .lrn_response_wrapper .lrn_texteditor_editable, .lrn_response_wrapper .lrn_toolbar, .lrn-assess-skip-to-content-link'
        );

        skipFromReadingItems.forEach((el) => {
          el.classList.add('rs_skip');
        });

        itemsApp.current?.on('button:readspeaker_toggle_btn:clicked', () => {
          if (isReadspeakerEnabledRef.current) {
            setShowReadspeaker((prev) => !prev);
            return;
          }

          // @ts-ignore
          window.rsConf = {
            general: {
              usePost: true,
            },
            settings: {
              hlScroll: 'scrolloff',
            },
          };

          const script = createReadspeakerScript();
          readerScriptRef.current = script;
          document.head.appendChild(script);

          assessRef.current
            ?.querySelectorAll('.learnosity-item')
            .forEach((item, index) => {
              item.classList.add(`reader-segment-${index}`);
              const button = createReadspeakerButton(
                assessRef.current,
                `readclass=reader-segment-${index}`
              );
              button.classList.add(`reader-button`);
              item.prepend(button);
            });

          setShowReadspeaker((prev) => !prev);
          isReadspeakerEnabledRef.current = true;
        });
        replaceVideosBasedOnLanguage();
        replaceImagesBasedOnLanguage();
        if (
          typeof (window as any).runGoogleTranslate === 'function' &&
          window.document.documentElement.lang !== 'en'
        ) {
          document
            .querySelectorAll('.lrn_choiceLabel')
            ?.forEach((label) => label.classList.add('notranslate'));
          (window as any).runGoogleTranslate(
            window.document.documentElement.lang || 'en'
          );
        }
      },
      errorListener() {
        setLearnosityLoading(false);
      },
    });

    return itemsApp;
  };

  React.useEffect(() => {
    return () => {
      if (readerScriptRef.current) {
        document.head.removeChild(readerScriptRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (isDistrictAssignment) {
      signDistrictAssignment({
        variables: {
          params: {
            referenceId: learnosityReferenceId || '',
            signItem: {
              renderingType: AssignmentLearnosityRenderingType.Assess,
              type: AssignmentLearnosityType.SubmitPractice,
              state: AssignmentLearnosityState.Initial,
              config: {
                metadata: { current_sheet_position: 0 },
                configuration: {
                  onsave_redirect_url: false,
                  onsubmit_redirect_url: false,
                  submit_criteria: {
                    type: 'attempted',
                    threshold: 100,
                  },
                },
              },
            },
          },
        },
      })
        .then((response) => {
          const signedRequest = response?.data?.daSignRequest?.sign;

          if (!signedRequest) {
            return;
          }

          initItemsApp(signedRequest);
        })
        .catch(() => {});
    } else {
      signAssignment({
        variables: {
          params: {
            itemSignedRequestInput: {
              referenceId: learnosityReferenceId,
              renderingType: AssignmentLearnosityRenderingType.Assess,
              type: AssignmentLearnosityType.SubmitPractice,
              state: AssignmentLearnosityState.Initial,
              config: {
                metadata: { current_sheet_position: 0 },
                configuration: {
                  onsave_redirect_url: false,
                  onsubmit_redirect_url: false,
                  shuffle_items: randomizeQuestionsOrder,
                  submit_criteria: {
                    type: 'attempted',
                    threshold: 100,
                  },
                },
              },
            },
          },
        },
      })
        .then((response) => {
          const signedRequest = response?.data?.signRequest?.signedRequest;

          if (!signedRequest) {
            return;
          }

          initItemsApp(signedRequest);
        })
        .catch(() => {});
    }
  }, []);

  return (
    <>
      {isFullScreen ? (
        <IconButton
          position={'absolute'}
          aria-label="full-screen"
          icon={<Icon name="closeOutlined" />}
          alignSelf="flex-end"
          mt={2}
          right={4}
          border={0}
          sx={{
            _hover: {
              bg: 'transparent',
              color: 'inherit',
            },
            _focus: {
              boxShadow: 'none',
            },
            _active: {
              bg: 'transparent',
            },
          }}
          onClick={() => handleExitFullScreen()}
        />
      ) : (
        <IconButton
          position="absolute"
          aria-label="full-screen"
          icon={<Icon name="fullScreen" />}
          alignSelf="flex-end"
          mt={2}
          right={4}
          border={0}
          sx={{
            _hover: {
              bg: 'transparent',
              color: 'inherit',
            },
            _focus: {
              boxShadow: 'none',
            },
            _active: {
              bg: 'transparent',
            },
          }}
          onClick={() => handleOpenFullScreen()}
        />
      )}
      <Box
        ref={assessRef}
        p={3}
        pt="2.875rem"
        sx={{
          '.reader-button': {
            position: 'relative',
            top: 4,
            marginTop: 0,
          },
          ...(!showReadspeaker && {
            '.reader-button': {
              display: 'none',
            },
          }),
        }}
      >
        <Box id="learnosity_assess" m="0 !important" zIndex="0" w="full" />
      </Box>
      <AssignmentPrompt
        hasUnsavedChanges={hasUnsavedChanges}
        onOk={(nextLocation) => {
          if (itemsApp.current) {
            itemsApp.current.save({ success: () => navigate(nextLocation) });
          }
        }}
      />
    </>
  );
};

export default AssessmentQuestions;
