import React, { useState } from 'react';
import {
  StudentFullScreenContext,
  StudentFullScreenContextType,
} from '@lon/shared/contexts';

const StudentFullScreenProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [isFullScreen, setIsFullScreen] = useState(false);

  const value: StudentFullScreenContextType = {
    isFullScreen,
    setIsFullScreen,
  };

  return (
    <StudentFullScreenContext.Provider value={value}>
      {children}
    </StudentFullScreenContext.Provider>
  );
};

export default StudentFullScreenProvider;
