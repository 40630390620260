import { Text } from '@chakra-ui/react';
import { useContext } from 'react';
import { Trans } from 'react-i18next';
import { NoContent as SharedNoContent } from '@lon/shared/components';
import { StudentGradeContext } from '@lon/shared/contexts';

const NoContent: React.FC = () => {
  const { isStudentBelowK3 } = useContext(StudentGradeContext);

  return (
    <SharedNoContent
      actions={<></>}
      contentProps={{ px: 'initial', mb: '180px' }}
    >
      <Trans
        i18nKey={
          isStudentBelowK3 ? 'notebook.emptyMessageK2' : 'notebook.emptyMessage'
        }
      >
        <Text variant="h5">No Classes available for you right now.</Text>
        <Text variant="n3">Contact your Teacher for more information.</Text>
      </Trans>
    </SharedNoContent>
  );
};

export default NoContent;
