import { Skeleton, SkeletonProps } from '@chakra-ui/react';
import { useContext } from 'react';
import { StudentGradeContext } from '@lon/shared/contexts';

const ContentSkeleton: React.FC<SkeletonProps> = (props) => {
  const { isStudentBelowK3 } = useContext(StudentGradeContext);

  return (
    <Skeleton
      h="full"
      w="full"
      borderRadius={{
        lg: isStudentBelowK3 ? '20px' : 'unset',
      }}
      data-testid="content-skeleton"
      {...props}
    />
  );
};

export default ContentSkeleton;
