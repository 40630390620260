import { AspectRatio, Skeleton } from '@chakra-ui/react';

const SidebarSkeleton = ({
  isStudentBelowK3,
  count = 5,
}: {
  isStudentBelowK3: boolean | null;
  count?: number;
}) => {
  return (
    <>
      {new Array(count).fill(null).map((_, index) => (
        <AspectRatio
          key={index}
          as="li"
          listStyleType="none"
          ratio={isStudentBelowK3 ? 11 / 17 : 26 / 36}
          width="100%"
          height="100%"
          data-testid="card-skeleton"
        >
          <Skeleton borderRadius={isStudentBelowK3 ? '10px' : 0} />
        </AspectRatio>
      ))}
    </>
  );
};

export default SidebarSkeleton;
