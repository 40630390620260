export const getUnattemptedQuestions = (
  itemsApp: ReturnType<typeof window.LearnosityItems.init>
) => {
  const attempted = itemsApp.attemptedQuestions() || [];
  const all = Object.keys(itemsApp.getQuestions() || {});

  return all
    .map((questionId, index) =>
      attempted.includes(questionId) ? -1 : index + 1
    )
    .filter((ind) => ind !== -1);
};

export const createReadspeakerButton = (doc: any, sectionTarget: any) => {
  const uri = encodeURIComponent(window.location.href);
  let target;
  if (sectionTarget === null) {
    if (
      doc.querySelector('.lm-accordion') ||
      doc.querySelector('.lm-carousel') ||
      doc.querySelector('#widget-preview-component')
    ) {
      target = 'readclass=active,accordion-button';
    } else if (doc.querySelector('.loader') !== null) {
      target = 'readclass=dialog-container,sr-only';
    } else if (doc.querySelector('.learnosity-item') !== null) {
      target = 'readclass=lm-ck-blank,sr-only,learnosity-item';
    } else if (doc.querySelector('.container') !== null) {
      target = 'readclass=container';
    } else {
      target = 'readclass=main';
    }
  }

  const preferences = JSON.parse(
    localStorage.getItem('voicePreferences') || '{}'
  );

  const language = window.document.documentElement.lang;
  const voice =
    language === 'es'
      ? preferences.spanishVoice || 'Francisco'
      : preferences.englishVoice || 'Sophie';
  const fullLanguage =
    language === 'es' ? (voice === 'Pilar' ? 'es_es' : 'es_us') : 'en_us';

  const rsButton = document.createElement('div');
  rsButton.style.marginBottom = '24px';
  rsButton.className = 'rs_skip rsbtn rs_preserve';
  rsButton.id = 'readspeaker_button';
  const rsAnchor = document.createElement('a');
  rsAnchor.rel = 'nofollow';
  rsAnchor.className = 'rsbtn_play';
  rsAnchor.title = 'Listen to this page using ReadSpeaker webReader';
  rsAnchor.href =
    `https://app-na.readspeaker.com/cgi-bin/rsent?customerid=12474&lang=${fullLanguage}&` +
    `${
      sectionTarget !== null ? sectionTarget : target
    }&voice=${voice}&url=${uri}`;
  rsButton.appendChild(rsAnchor);
  const rsSpan1 = document.createElement('span');
  rsSpan1.className = 'rsbtn_left rsimg rspart';
  rsAnchor.appendChild(rsSpan1);
  const rsSpan2 = document.createElement('span');
  rsSpan2.className = 'rsbtn_text';
  rsSpan1.appendChild(rsSpan2);
  const rsSpan3 = document.createElement('span');
  rsSpan3.append('Listen');
  rsSpan2.appendChild(rsSpan3);
  const rsSpan4 = document.createElement('span');
  rsSpan4.className = 'rsbtn_right rsimg rsplay rspart';
  rsAnchor.appendChild(rsSpan4);

  return rsButton;
};

export const createReadspeakerScript = () => {
  const readspeakerScript = document.createElement('script');
  readspeakerScript.type = 'text/javascript';
  readspeakerScript.src =
    'https://cdn-na.readspeaker.com/script/12474/webReaderForEducation/learnosity/current/ReadSpeaker.Learnosity.js';
  readspeakerScript.id = 'rs_req_Init';
  return readspeakerScript;
};
